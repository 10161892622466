// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
// import useJwt from '@src/auth/jwt/useJwt'

export const initialTypes = () => {
  const item = window.localStorage.getItem('types')
  if (item === "undefined") {
    return []
  }
  return item ? JSON.parse(item) : []
}
export const typeSlice = createSlice({
    name: 'types',
    initialState: {
        types: initialTypes()
    },
    reducers: {
        updateTypes: (state, action) => {
          state.user = action.payload
          localStorage.setItem('types', JSON.stringify(action.payload))
          let date = new Date()
          date.setUTCHours(date.getUTCHours() + 24)
          localStorage.setItem('nextCheckTypes', date)
        }
    }
})

export const { updateTypes } = typeSlice.actions

export default typeSlice.reducer
