// ** I18n Imports

import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import TimeAgo from 'javascript-time-ago'

import pl from 'javascript-time-ago/locale/pl.json'
import en from 'javascript-time-ago/locale/en.json'
import sr from 'javascript-time-ago/locale/sr.json'
import es from 'javascript-time-ago/locale/es.json'

import { isUserLoggedIn, getUserData } from '@utils'

let lang = ''

TimeAgo.addLocale(pl)
TimeAgo.addLocale(en)
TimeAgo.addLocale(sr)
TimeAgo.addLocale(es)


if (isUserLoggedIn() !== null) {
  const user = getUserData().user
  lang = user.lang.code
  TimeAgo.setDefaultLocale(lang)
} else {
  TimeAgo.setDefaultLocale('en')
}

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: {
      loadPath: '/assets/data/locales/{{lng}}.json'
    },
    fallbackLng: {
      'pl-PL' : ['pl'],
      default: ['en']
    },
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    }
  })

export default i18n

export function trans(key, opts) {
  return i18n.t(key, {
    ...opts,
    postProcess: `reactPostprocessor`
  })
}