// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import cart from './cart'
import types from './types'
import bookmakers from './bookmakers'
const rootReducer = {
  auth,
  navbar,
  layout,
  cart,
  types,
  bookmakers
}

export default rootReducer
